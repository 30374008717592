import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import vuetify from './plugins/vuetify'
import axios from "axios";
import VueAxios from "vue-axios";
// import VueConfirmDialog from 'vue-confirm-dialog'
// import vSelect from 'vue-select'

// Vue.component('v-select', vSelect)
// Vue.use(VueConfirmDialog)
// Vue.component('vue-confirm-dialog', VueConfirmDialog.default)
Vue.use(VueAxios, axios);
Vue.config.productionTip = false
Vue.prototype.$CurrencySymbol = null;

new Vue({
    router,
    vuetify,
    render: h => h(App)
}).$mount('#app')