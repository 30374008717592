<template>
  <div class="home">
    <SignIn />
    <v-divider></v-divider>
  </div>
</template>

<script>
import SignIn from "../components/SignIn";

export default {
  name: "Home",
  components: {
    SignIn
  },
  data() {
    return {};
  },
  mounted() {
    if (localStorage.getItem("BaseURL") === null) {
      this.$router.push("/settings");
    } else {
      if (localStorage.getItem("Token") === null) {
        this.$router.push("/login");
        this.showCont = false;
      } 
    }
  }
};
</script>

<style scoped></style>
