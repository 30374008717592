import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: "/settings",
        name: "settings",
        component: () =>
            import ( /* webpackChunkName: "about" */ "../views/Settings.vue")
    },
    {
        path: "/sales",
        name: "sales",
        component: () =>
            import ( /* webpackChunkName: "about" */ "../views/Sales.vue")
    },
    {
        path: "/gsales",
        name: "gsales",
        component: () =>
            import ( /* webpackChunkName: "about" */ "../views/GeneralSales.vue")
    },
    {
        path: "/login",
        name: "login",
        component: () =>
            import ( /* webpackChunkName: "about" */ "../views/Login.vue")
    },
    {
        path: "/preference",
        name: "preference",
        component: () =>
            import ( /* webpackChunkName: "about" */ "../views/Preferences.vue")
    },
    {
        path: "/pdtscan",
        name: "pdtscan",
        component: () =>
            import ( /* webpackChunkName: "about" */ "../views/Pdtview.vue")
    },
    {
        path: "/result",
        name: "result",
        component: () =>
            import ( /* webpackChunkName: "about" */ "../views/Barcoderesult.vue")
    },
    {
        path: "/goods",
        name: "goods",
        component: () =>
            import ( /* webpackChunkName: "about" */ "../views/Goodsreceipt.vue")
    },
    {
        path: "/salesorder",
        name: "salesorder",
        component: () =>
            import ( /* webpackChunkName: "about" */ "../views/Salesorder.vue")
    },
    {
        path: "/itemReg",
        name: "itemReg",
        component: () =>
            import ( /* webpackChunkName: "about" */ "../views/GitemReg.vue")
    },
    {
        path: "/salesorderitemReg",
        name: "salesorderitemReg",
        component: () =>
            import ( /* webpackChunkName: "about" */ "../views/SalesorderitemReg.vue")
    },
    {
        path: "/editGR",
        name: "editGR",
        component: () =>
            import ( /* webpackChunkName: "about" */ "../views/EditGR.vue")
    },
    {
        path: "/editSO",
        name: "editSO",
        component: () =>
            import ( /* webpackChunkName: "about" */ "../views/EditSO.vue")
    },
    //physical area
    {
        path: "/physical",
        name: "physical",
        component: () =>
            import ( /* webpackChunkName: "about" */ "../views/Stock.vue")
    },
    {
        path: "/phyitemReg",
        name: "phyitemReg",
        component: () =>
            import ( /* webpackChunkName: "about" */ "../views/PhyitemReg.vue")
    },
    {
        path: "/editPS",
        name: "editPS",
        component: () =>
            import ( /* webpackChunkName: "about" */ "../views/EditPS.vue")
    },
    {
        path: "/register",
        name: "Register",
        component: () =>
            import ("../views/Register.vue")
    },
    {
        path: "/printbarcode",
        name: "Printbarcode",
        component: () =>
            import ("../views/PrintBarcode.vue")
    },

]

const router = new VueRouter({
    routes
})

export default router