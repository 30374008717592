<template>
  <div>
    <Header />
    <v-snackbar Bottom :timeout="2000" color="red" v-model="snackbar_">
      You do not have permission to access this option !
    </v-snackbar>
    <v-container style="padding:0px;">
      <v-card
        class="mx-auto my-12"
        style="margin-top: 15px !important;margin-bottom: 5px !important;max-width:100% !important;box-shadow:none;"
        max-width="374"
      >
        <v-img
          height="200"
          src="https://miro.medium.com/max/1200/1*U-G1yCK7UI3JESd1OJqMCw.gif"
        ></v-img>

        <v-card-text>
          <v-row> </v-row>
        </v-card-text>
      </v-card>
      <v-spacer></v-spacer>
      <div style="margin-top:2em;">
        <v-bottom-navigation
          grow
          style="-webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;"
        >
          <v-btn value="recent" cols="4" @click="camscan">
            <span>Camera Scan</span>

            <v-icon style="color: #1976d2;">mdi-barcode-scan</v-icon>
          </v-btn>
          <v-btn value="recent" cols="4" @click="goPdt">
            <span>PDT Scan</span>

            <v-icon style="color: #1976d2;">mdi-barcode</v-icon>
          </v-btn>

          <v-btn value="favorites" cols="4" @click="goods">
            <span>Goods Receipt</span>

            <v-icon style="color: #1976d2;">mdi-store</v-icon>
          </v-btn>
        </v-bottom-navigation>
      </div>
      <div style="margin-top:2em;">
        <v-bottom-navigation
          grow
          style="-webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;"
        >
          <v-btn value="nearby" cols="4" @click="physical">
            <span>Physical Stock</span>

            <v-icon style="color: #1976d2;">mdi-google-analytics</v-icon>
          </v-btn>

          <v-btn value="favorites" cols="4" @click="Printbarcode">
            <span>Print Barcode</span>

            <v-icon style="color: #1976d2;">mdi-printer</v-icon>
          </v-btn>

          <v-btn value="nearby" cols="4">
            <span>Account</span>

            <v-icon style="color: #1976d2;">mdi-account</v-icon>
          </v-btn>
        </v-bottom-navigation>
      </div>
      <div style="margin-top:2em;">
        <v-bottom-navigation
          grow
          style=" -webkit-box-shadow: none;-moz-box-shadow: none;
  box-shadow: none;"
        >
          <v-btn value="recent" cols="4" @click="sales()">
            <span>Sales</span>

            <v-icon style="color: #1976d2;">mdi-clipboard-flow</v-icon>
          </v-btn>

          <v-btn value="favorites" cols="4" @click="Preferences()">
            <span>Preferences</span>

            <v-icon style="color: #1976d2;">mdi-eye</v-icon>
          </v-btn>

          <v-btn value="nearby" cols="4" @click="salesorder()">
            <span>Sales Order</span>

            <v-icon style="color: #1976d2;">mdi-cart</v-icon>
          </v-btn>
        </v-bottom-navigation>
      </div>
    </v-container>
    <v-dialog
    v-model="saledialog"
    width="auto"
  >
    <v-card>
      <v-card-text>
        <v-row no-gutters style="padding-top:10px;">
          <v-col cols style="justify-content:center;text-align: center;padding:5px;" v-show="Allpermission == true" >
            <v-card text="text-align:center;">
              <!-- {{ Allpermission }} -->
              <v-icon style="color: #1976d2;font-size: 2em;">mdi-cube</v-icon>
              <v-btn color="primary" block  @click="goSale(1)">Counter Sale</v-btn>
            </v-card>
           
          </v-col>
          <v-col cols style="justify-content:center;text-align: center;padding:5px;" v-show="Counterpermission == true">
            <v-card text="">
              <!-- {{ Counterpermission }} -->
              <v-icon style="color: #1976d2;font-size: 2em;">mdi-sale</v-icon>
            <v-btn color="primary" block @click="goSale(2)">All Sale</v-btn>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      
    </v-card>
  </v-dialog>
    <v-dialog v-model="BarcodeModal" persistent width="500">
      <v-card>
        <v-card-title
          style="color:rgb(0 99 145);font-size:17px;text-align:center"
          >Scan barcode</v-card-title
        >

        <v-card-text>
          <StreamBarcodeReader
            @decode="a => onDecode(a)"
            @loaded="() => onLoaded()"
          ></StreamBarcodeReader>
        </v-card-text>
        <v-card-actions hidden>
          <v-spacer></v-spacer>
          <v-btn
            small
            @click="BarcodeModal = false"
            style="background-color:#FB8C00;color:#FFF"
          >
            Cancel
          </v-btn>

          <v-btn
            small
            @click="BarcodeModal = false"
            style="background-color:#2E7D32;color:#FFF"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import { StreamBarcodeReader } from "vue-barcode-reader";
import Header from "./Header.vue";
export default {
  components: {
    StreamBarcodeReader,
    Header
  },
  data() {
    return {
      image: "@/assets/images/appbg.png",
      sheet: false,
      barcodescannarmodal: false,
      BarcodeModal: false,
      URL__: localStorage.getItem("BaseURL"),
      saledialog:false,
      ios:false,
      Allpermission:false,
      Counterpermission:false,
      snackbar_:false,
     
    }
  },
  beforeMount(){
   
  },
  mounted() {
    
    this.string = this.URL__;
    this.substring = "https";
    this.Check_url = this.string.includes(this.substring);
    if (this.Check_url == true) {
      // alert(this.Check_url)
      this.ios = this.Check_url
    } 
    else {
      this.ios = this.Check_url;
    }
    
  
   this.getrolesettingsdictionary();
 
   window.PermSave = this.PermSave;
   window.PerApiitems = this.PerApiitems;
   
   localStorage.setItem("ItemDetails",'[]');
   localStorage.setItem("physicalItemDetails",'[]');
   localStorage.removeItem("cangoback");
  },
 
  methods: {
    getrolesettingsdictionary()
    {
      let rolename = localStorage.getItem("role");
      if (this.ios == true) {
        this.axios
          .get(this.URL__ + "/api/common/getrolesettingsdictionary?rolename="+rolename+"&categories=common", null)
          .then(
            (response) => {
              console.log("output" + JSON.stringify(response.data));
              localStorage.setItem("HideCost",response.data.HideItemCostDetails)
              localStorage.setItem("HideAverageCost",response.data.HideAverageCost)
              this.getItems_api();
            },
            (error) => {
              console.log("error" + error);
              this.error = "Something went wrong..!";
            }
          );
      }
      else{
        const myobj = {};
        var obj = JSON.stringify(myobj);
        var method = "Get";
        var funtn_ = "PermSave";
        var url_ = this.URL__ + "/api/common/getrolesettingsdictionary?rolename="+rolename+"&categories=common";

        var JsonData = {
          Json: [{ URL: url_, Method: method, Params: obj, Function: funtn_ }],
        };

        var aaa = JSON.stringify(JsonData);
        this.SendOBJ = aaa;
        // alert(this.SendOBJ)
        window.interface.CommonMethod(this.SendOBJ);
      }
    },
    PermSave(res_per)
    {
      let objresult1 = JSON.parse(res_per);
      localStorage.setItem("HideCost", JSON.stringify(objresult1.HideItemCostDetails));
      localStorage.setItem("HideAverageCost", JSON.stringify(objresult1.HideAverageCost));
      this.getItems_api();
      // alert(localStorage.getItem("HideAverageCost"))
    },
    getItems_api()
    {
      alert(this.ios)
      if (this.ios == false) {
        this.axios
          .get(this.URL__ + "/api/item", null)
          .then(
            (response) => {
              console.log("Items" + JSON.stringify(response.data));
              localStorage.setItem("item",JSON.stringify(response.data));
              this.getCustomer_api();
            },
            (error) => {
              console.log("error" + error);
              this.error = "Something went wrong..!";
            }
          );
      }
      else{
        const httpUrl = 'http://eademoapi.linkwayapps.com/api/item';
    
// Define the headers to include in your request
        const headers = {
          'Access-Control-Allow-Origin': '*', // Replace '*' with the actual allowed origins if needed
          'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
          'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        };
        axios.get(httpUrl,{headers})
          .then((response) => {
            // Handle the successful response here
             alert("Items ashik" + JSON.stringify(response.data));
          })
          .catch((error) => {
            // Handle Axios errors here
            console.error(error);
          });
        // const myobj = {};
        // var obj = JSON.stringify(myobj);
        // var method = "Get";
        // var funtn_ = "PerApiitems";
        // var url_ = this.URL__ + "/api/item";

        // var JsonData = {
        //   Json: [{ URL: url_, Method: method, Params: obj, Function: funtn_ }],
        // };

        // var aaa = JSON.stringify(JsonData);
        // this.SendOBJ = aaa;
     
        // window.interface.CommonMethod(this.SendOBJ);
      }
    },
    PerApiitems(res_item) {
          
          let obj = JSON.parse(res_item);
          // alert("aa"+JSON.stringify(obj));
        //  this.b_itemname = obj.ItemName;
    },
   
    getCustomer_api()
    {
      if (this.ios == true) {
        this.axios
          .get(this.URL__ + "/api/customer", null)
          .then(
            (response) => {
              console.log("customer" + JSON.stringify(response.data));
              localStorage.setItem("customer",JSON.stringify(response.data));
              this.getSupplier_api();
              // let data = JSON.stringify(response.data);
              // for(this.i = 0;this.i < data; this.i++)
              // {

              // }
            },
            (error) => {
              console.log("error" + error);
              this.error = "Something went wrong..!";
            }
          );
      }
      else{
        const myobj = {};
        var obj = JSON.stringify(myobj);
        var method = "Get";
        var funtn_ = "customerSave";
        var url_ = this.URL__ + "/api/customer";

        var JsonData = {
          Json: [{ URL: url_, Method: method, Params: obj, Function: funtn_ }],
        };

        var aaa = JSON.stringify(JsonData);
        this.SendOBJ = aaa;
        // alert(this.SendOBJ)
        // window.interface.CommonMethod(this.SendOBJ);
        try {
      // Attempt to call the JavaScript function
          window.interface.CommonMethod(this.SendOBJ);
          // alert("aa")
        } catch (e) {
          // alert("Error calling PerApiitems:", e);
        }
      }
    },
    customerSave(res_item) {
        // alert("aa"+JSON.stringify(res_item))
        if (res_item) {
          // alert("ItemSave function called with data: " + JSON.stringify(res_item));
      // Your logic to save the item
      // For example, you can access its properties like item.itemId, item.itemCode, etc.
        } else {
          // Handle the case where 'item' is undefined
          // alert("Item is undefined");
          // You can also return early or display an error message to the user
        }
       
      },
    getSupplier_api()
    {
      if (this.ios == true) {
        this.axios
          .get(this.URL__ + "/api/supplier", null)
          .then(
            (response) => {
              console.log("supplier" + JSON.stringify(response.data));
              localStorage.setItem("supplier",JSON.stringify(response.data))
              // let data = JSON.stringify(response.data);
              // for(this.i = 0;this.i < data; this.i++)
              // {

              // }
            },
            (error) => {
              console.log("error" + error);
              this.error = "Something went wrong..!";
            }
          );
      }
      else{

//         try{
//           // Replace this URL with your PHP proxy server URL
//           // Replace this URL with your PHP proxy server URL
// const proxyServerUrl = 'https://www.singaporewebdesigner.org/a/pxy.php';
// const unsecuredUrl = 'http://eademoapi.linkwayapps.com/api/item'; // Unsecured URL

// // Make an HTTP request through the PHP proxy server
// axios.get(proxyServerUrl + '?target=' + unsecuredUrl)
//   .then(response => {
//     console.log('Items', response.data);
//     // Handle the response from the proxy server
//   })
//   .catch(error => {
//     console.error('Error:', error);
//     // Handle errors
//   });

//             } catch (e) {
//           alert("Error calling ItemSave:", e);
//         }
        const myobj = {};
        var obj = JSON.stringify(myobj);
        var method = "Get";
        var funtn_ = "supplierSave";
        var url_ = this.URL__ + "/api/supplier";

        var JsonData = {
          Json: [{ URL: url_, Method: method, Params: obj, Function: funtn_ }],
        };

        var aaa = JSON.stringify(JsonData);
        this.SendOBJ = aaa;
        // alert(this.SendOBJ)
        // window.interface.CommonMethod(this.SendOBJ);
        try {
      // Attempt to call the JavaScript function
          window.interface.CommonMethod(this.SendOBJ);
          // alert("aa")
        } catch (e) {
          // alert("Error calling ItemSave:", e);
        }
      }
    },
    supplierSave(res)
    {
       let obj = JSON.parse(res);
    //  alert(JSON.stringify(obj));
      // this.b_itemname = obj.ItemName;
     // alert("Res "+res)
    },
    log(){
      localStorage.removeItem("Token");
       this.$router.push("/login");
    },
    onDecode(a) {
      // console.log("Result Barcode " + a);
      // this.text = a;
      if (a != null || a != "") {
        this.BarcodeModal = false;
        localStorage.setItem("barcode", a);
        this.$router.push("/result");
      }
    },
    onLoaded() {
      console.log("load");
    },
    camscan() {
      this.string = localStorage.getItem("BaseURL");
      this.substring = "https";
      this.Check_url = this.string.includes(this.substring);
      if (this.Check_url == true) {
        // alert(this.Check_url);
        this.BarcodeModal = true;
        // window.AndroidInterface.scanBarcode();
      } else {
           this.BarcodeModal = true;
        // alert(this.Check_url);
        // window.AndroidInterface.scanBarcode();
        // this.ios = this.Check_url;
      }
    },
   
    getPerm_fromserver()
    {
      
      let rolename = localStorage.getItem("role");
      if (this.ios == true) {
        this.axios
          .get(this.URL__ + "/api/common/GetFormPermissions?RoleName="+rolename+"&FormNames=frmItem~frmPrintBarcode~frmCounterSaleSummary~frmSaleReg", null)
          .then(
            (response) => {
              console.log("Permission" + JSON.stringify(response.data));
              localStorage.setItem('permissionList', JSON.stringify(response.data));
             
            },
            (error) => {
              console.log("error" + error);
              // this.error = "Something went wrong..!";
            }
          );
      }
      else{
        const myobj = {};
        var obj = JSON.stringify(myobj);
        var method = "Get";
        var funtn_ = "NativegetPerm_fromserver";
        var url_ = this.URL__ + "/api/common/GetFormPermissions?RoleName="+rolename+"&FormNames=frmItem~frmPrintBarcode~frmCounterSaleSummary~frmSaleReg";

        var JsonData = {
          Json: [{ URL: url_, Method: method, Params: obj, Function: funtn_ }],
        };

        var aaa = JSON.stringify(JsonData);
        this.SendOBJ = aaa;
        // alert(this.SendOBJ)
        window.interface.CommonMethod(this.SendOBJ);
      }
    },
    NativegetPerm_fromserver(res_per2) {
   
     let perResult2 = JSON.parse(res_per2);
    //  alert(JSON.stringify(perResult2));
     localStorage.setItem("permissionList", JSON.stringify(perResult2));
     this.navigateSales();
    },
    
    

    


    //----------------------------*****************************----------------
    goPdt() {
      this.$router.push("pdtscan");
    },
    goods() {
      this.$router.push("goods");
    },
    physical() {
      this.$router.push("physical");
    },
    Preferences()
    {
      // Preferences.vue
      this.$router.push("preference");
    },
     Printbarcode() {
      this.$router.push("Printbarcode");
    },
    salesorder() {
      this.$router.push("salesorder");
    },
    sales() {
      this.getPerm_fromserver();
      // let perData = JSON.parse(localStorage.getItem("permissionList"));
      //       alert("Data"+perData[2].crud[1])
            
      //       this.Counterpermission =perData[2].crud[1];
      //       this.Allpermission = perData[3].crud[1];
        // if(this.Allpermission == true && this.Counterpermission == true)
        // {
        //   // alert("open dailoge")
        //   this.saledialog = true;
        // }
        // else if(this.Allpermission == false && this.Counterpermission == true)
        // {
        //   // alert("secn dailoge")
        //   this.$router.push("sales");
        // }
        // else if(this.Allpermission == true && this.Counterpermission == false)
        // {
        //   // alert("thrd dailoge")
        //   this.$router.push("gsales");
        // }
        // else{
        //  // alert("last dailoge")
        //   // this.$router.push("gsales");
        // }
        
        // 
    
    },
    navigateSales()
    {
            let perData = JSON.parse(localStorage.getItem("permissionList"));
            // alert("Data"+perData[3].crud[1])
            
            this.Counterpermission =perData[2].crud[1];
            this.Allpermission = perData[3].crud[1];
             if(this.Allpermission == true && this.Counterpermission == true)
              {
                // alert("open dailoge")
                this.saledialog = true;
              }
              else if(this.Allpermission == false && this.Counterpermission == true)
              {
                // alert("secn dailoge")
                this.$router.push("sales");
              }
              else if(this.Allpermission == true && this.Counterpermission == false)
              {
                // alert("thrd dailoge")
                this.$router.push("gsales");
              }
              else{
              this.snackbar_ =true;
              }
    },
    goSale(type)
    {
      if(type === 1)
      {
        this.$router.push("sales");
        // alert(1)
      }else{
        this.$router.push("gsales");
        // alert(2)
      }
    },

    //******************** All permission----------------------------
   
    

    
  },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
