<template>
  <v-app-bar app dense color="info" style="color:#fff;">
    
    <v-btn icon style="color:#fff;" v-if="isChildRoute" @click="previousPage">
    <v-icon>mdi-keyboard-backspace</v-icon>
   </v-btn>
    <v-toolbar-title @click="goHome">{{Name}}</v-toolbar-title>

    <v-spacer></v-spacer>

    <v-btn icon style="color:#fff;" @click="goHome" v-if="isChildRoute">
      <v-icon>mdi-home</v-icon>
    </v-btn>
    <v-btn icon style="color:#fff;"  @click="gosettings" hidden>
      <v-icon>mdi-cog</v-icon>
    </v-btn>

    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <v-avatar size="36" class="mr-2" v-on="on"  style="    left: 1em !important;">
          <v-img :src="profile_" alt="noimage"></v-img>
        </v-avatar>
      </template>
      <v-list>
        <v-list-item style="min-height:35px;background:#2196f3 ; font-size:13px;color:#fff!important;" >
          <v-list-item-title>{{userDetails.username}}</v-list-item-title>
        </v-list-item>
        <v-list-item @click="logout" style="min-height:35px;">
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
        <v-list-item @click="gosettings" style="min-height:35px;">
          <v-list-item-title>Settings</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>
<script>
export default {
  data() {
    return {
      Name: "Smart Collector",
      userDetails:[],
      profile_:''
    };
  },
  created(){
            this.userDetails = JSON.parse(localStorage.getItem("Accounts"));
            // alert(JSON.stringify(this.userDetails.photo));
            // if(this.userDetails.photo == null || this.userDetails.photo =="")
            // {
            //   this.profile_ = "images";
            // }else{
            //   this.profile_ = "data:image/png;base64,"+this.userDetails.photo
            // }
              if (!this.userDetails.photo || this.userDetails.photo == "" || this.userDetails.photo == "null") {
                // alert("aa"+JSON.stringify(this.userDetails.photo));
                this.profile_ = require('@/assets/images/user.png'); // Assuming the default image is in the "assets/images" folder
              } else {
                // alert("bb"+JSON.stringify(this.userDetails.photo));
                this.profile_ = "data:image/png;base64," + this.userDetails.photo;
              }
            
  },
  methods: {
    goHome() {
      this.$router.push("/");
    },
    previousPage()
    {
      window.history.back();
    },
    gosettings()
    {
      this.$router.push("settings");
    },
    logout()
    {
       localStorage.removeItem("Token");
       this.$router.push("/login");
    }
  },
  computed: {
    isChildRoute() {
      return this.$route.path !== '/';
    },
  },
  
  
};
</script>
