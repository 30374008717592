<template>
  <v-app>
    <v-snackbar
      right
      top
      :value="updateExists"
      :timeout="50000"
      color="#2196f3"
    >
      A newer version of this app is available
      <v-btn text @click="refreshApp">
        Update
      </v-btn>
    </v-snackbar>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import update from "./mixins/update";
export default {

  components: {
  
  },
  data() {
    return {};
  },
   mixins: [update],
  mounted() {
    window.isnative = this.isNative;
    
  },
  methods: {
    isNative() {
      var IsNativeApp = window.interface.getisNative();
        if(IsNativeApp == "NativeApp")
        {
       
         localStorage.setItem("IsNativeApp",true);
        }else{
        
         localStorage.setItem("IsNativeApp",false);
        }
    }
  },

 
};
</script>
